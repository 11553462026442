@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@property --border-angle {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

.text-align-center {
  text-align: center;
}

.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}
.react-flow {
  --bg-color: rgb(17, 17, 17);
  --text-color: rgb(243, 244, 246);
  --node-border-radius: 10px;
  --node-box-shadow: 10px 0 15px rgba(42, 138, 246, 0.3),
    -10px 0 15px rgba(233, 42, 103, 0.3);
  /* background-color: var(--bg-color); */
  color: var(--text-color);
}

.react-flow__node-turbo {
  border-radius: var(--node-border-radius);
  display: flex;
  height: 70px;
  min-width: 150px;
  font-family: "Fira Mono", Monospace;
  font-weight: 500;
  letter-spacing: -0.2px;
  box-shadow: var(--node-box-shadow);
}

.react-flow__node-turbo .wrapper {
  overflow: hidden;
  display: flex;
  padding: 2px;
  position: relative;
  border-radius: var(--node-border-radius);
  flex-grow: 1;
}

.gradient:before {
  content: "";
  position: absolute;
  padding-bottom: calc(100% * 1.41421356237);
  width: calc(100% * 1.41421356237);
  background: conic-gradient(
    from -160deg at 50% 50%,
    #22c55e 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    #22c55e 360deg
  );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.react-flow__node-turbo.selected .wrapper.gradient:before {
  content: "";
  background: conic-gradient(
    from -160deg at 50% 50%,
    #22c55e 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    rgba(42, 138, 246, 0) 360deg
  );
  animation: spinner 4s linear infinite;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -1;
}

@keyframes spinner {
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.react-flow__node-turbo .inner {
  background: var(--bg-color);
  padding: 16px 20px;
  border-radius: var(--node-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.react-flow__node-turbo .icon {
  margin-right: 8px;
}

.react-flow__node-turbo .body {
  display: flex;
}

.react-flow__node-turbo .title {
  font-size: 16px;
  margin-bottom: 2px;
  line-height: 1;
}

.react-flow__node-turbo .subline {
  font-size: 12px;
  color: #777;
}

.react-flow__node-turbo .cloud {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 2px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
}

.react-flow__node-turbo .cloud div {
  background-color: var(--bg-color);
  flex-grow: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-flow__handle {
  opacity: 1;
  padding: 0.3rem;
  border-radius: 10px;
  background-color: #22c55e;
}

.react-flow__handle.source {
  background-color: #22c55e;
  overflow-y: visible;
  position: fixed;
  max-width: 10px;
  bottom: -7px;
  border: 1px solid black;
  align: center;
}

.react-flow__handle.target {
  top: -7px;
  overflow-y: visible;
  position: fixed;
  background-color: #22c55e;
}

.react-flow__node:focus {
  outline: none;
}

.react-flow__edge .react-flow__edge-path {
  stroke: url(#edge-gradient);
  stroke-width: 2;
  stroke-opacity: 0.75;
}

.react-flow__controls button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid #95679e;
  border-bottom: none;
}

.react-flow__controls button:hover {
  background-color: rgb(37, 37, 37);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-bottom: 1px solid #95679e;
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: var(--text-color);
}

.react-flow__attribution {
  background: rgba(200, 200, 200, 0.2);
}

.react-flow__attribution a {
  color: #95679e;
}


/* reset */
button {
  all: unset;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state="open"] {
  background-color: var(--violet4);
  color: var(--violet11);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve8);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--violet9);
  color: var(--violet1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve11);
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--violet6);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
  background-color: var(--violet3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve8);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}  

.prose, .prose-invert {
  --tw-prose-headings: #f3f4f6 !important; /* Lighter headings (gray-100) */
  --tw-prose-body: #9ca3af !important; /* Light gray text (gray-400) */
  --tw-prose-bold: #f3f4f6 !important; /* Bold text in light gray (gray-100) */
  --tw-prose-counters: #9ca3af !important; /* Light gray for counters (gray-400) */
  --tw-prose-bullets: #9ca3af !important; /* Light gray for bullets (gray-400) */
  --tw-prose-quotes: #e5e7eb !important; /* Light gray for blockquotes (gray-200) */
  --tw-prose-hr: #4b5563 !important; /* Horizontal rule in medium gray (gray-600) */

  --tw-prose-code: #e5e7eb !important; /* Light gray for code (gray-200) */
  --tw-prose-pre-bg: #1f2937 !important; /* Dark background for preformatted code (gray-800) */
  --tw-prose-pre-code: #f3f4f6 !important; /* Light color for preformatted code (gray-100) */

  --tw-prose-links: #60a5fa !important; /* Light blue for links (blue-400) */
}
